<template>
  <Form
    ref="formValidate"
    :model="formValidate"
    :rules="ruleValidate"
    :label-width="80"
  >
    <FormItem label="手机号" prop="iphone">
      <input
        v-model="formValidate.iphone"
        type="text"
        placeholder="请输入手机号"
      />
    </FormItem>
    <FormItem label="验证码" prop="code">
      <input
        v-model="formValidate.code"
        type="text"
        placeholder="请输入验证码"
        style="width: 70%"
      />
      <span class="code" @click="getMsgCode()">
        {{ isActive ? `剩余${count}秒` : '发送验证码' }}
      </span>
    </FormItem>

    <div class="form-item">
      <div class="agree">
        <!-- <XtxCheckbox v-model="accountIsAgreeField" /> -->
        <Checkbox v-model="single">
          <span>勾选即代表同意</span>
          <a href="javascript:">《用户注册协议及隐私条款》</a>
        </Checkbox>
      </div>
      <!-- <div class="error"
           v-if="accountIsAgreeError">
        <i class="iconfont icon-warning">{{ accountIsAgreeError }}</i>
      </div> -->
    </div>
    <FormItem>
      <!-- <button type="submit"
              class="btn">下一步</button> -->
      <!-- <Button type="primary"
              @click="handleSubmit('formValidate')">Submit</Button> -->
      <Button
        class="btn"
        style="margin-left: 8px"
        @click="handleSubmit('formValidate')"
      >
        下一步
      </Button>
    </FormItem>
  </Form>
</template>
<script>
export default {
  data () {
    return {
      single: false,
      isActive: false, // 是否发送认证码
      isAuth: false, // 认证码是否成功
      formValidate: {
        iphone: '',
        code: '',
      },
      ruleValidate: {
        iphone: [
          {
            required: true,
            message: '手机号不能为空',
            trigger: 'blur',
          },
        ],
        code: [
          {
            required: true,
            message: '验证码不能为空',
            trigger: 'blur',
          },
        ],
      },
      // timer: null,
      // count: 60,

      count: '',
      timer: null,
    }
  },
  methods: {
    handleSubmit (name) {
      const getData = {}
      getData.tel = this.formValidate.iphone
      getData.code = this.formValidate.code

      if (!this.single) {
        this.$Message.error('请勾选同意用户协议')
      } else if (!this.isAuth) {
        this.$Message.error('请输入正确的验证码')
      } else {
        this.$refs[name].validate((valid) => {
          if (valid) {
            this.$store
              .dispatch('LoginMsgCodeNext', getData)
              .then((response) => {

                if (response.returnCode === '1') {
                  this.$emit('stepEvent', {
                    num: 2,
                    iphone: this.formValidate.iphone,
                    code: this.formValidate.code,
                  })
                }
              })
              .catch(() => {})
          } else {
            this.$Message.error('请填写正确的信息')
          }
        })
      }
    },

    getMsgCode () {
      if (this.formValidate.iphone === '') {
        this.$Message.error('电话号码不能为空')
      } else {
        if (!/^1[34578]\d{9}$/.test(this.formValidate.iphone)) {
          this.$Message.error('请输入正确的电话号码')
        } else {
          this.$store
            .dispatch('LoginMsgCode', this.formValidate.iphone)
            .then((response) => {

              if (response.returnCode === '1') {
                this.isAuth = this.timer
              } else {
                this.$Message.error(response.result)
              }
            })
            .catch(() => {})
          const TIME_COUNT = 60
          if (!this.timer) {
            this.count = TIME_COUNT
            this.isActive = true
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count -= 1
              } else {
                this.isActive = false
                clearInterval(this.timer)
                this.timer = null
              }
            }, 1000)
          }
        }
      }
    },
  },
}
</script>

<style lang="less" scoped>
input {
  padding-left: 8px;
  border: 1px solid #cfcdcd;
  height: 36px;
  line-height: 36px;
  width: 100%;
}
.btn {
  display: block;
  width: 100%;
  height: 40px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  background: @xtxColor;
  border: none;
  &.disabled {
    background: #cfcdcd;
  }
}
.agree {
  padding-left: 38px;
  // float: right;

  padding-bottom: 10px;
  a {
    color: #409eff;
  }
}

/deep/ .ivu-checkbox-checked .ivu-checkbox-inner {
  border-color: #d12b23;
  background-color: #d12b23;
}
.code {
  position: absolute;
  right: 0;
  top: 0;
  line-height: 36px;
  width: 80px;
  height: 36px;
  font-size: 14px;

  color: #409eff;
  &:hover {
    cursor: pointer;
  }
}
</style>
